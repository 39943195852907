import React, { useState, useEffect, useContext } from "react";
import { payment, salaryCut } from "../assets/icons";
import { AuthCountext } from "./AuthCountext";
import DashboardDiv from "./subcomponent/DashboardDiv";

const AdvancePaymentComp = () => {
  const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [advancePayment, setAdvancePayment] = useState(0);
  const [installments, setInstallments] = useState(0);

  const list = [
    {
      name: "advancepayment",
      title: "السلف",
      result: advancePayment,
      img: payment,
      link: "/advance",
    },
    {
      name: "installments",
      title: "الاقساط",
      result: installments,
      img: salaryCut,
      link: "/installments",
    },
  ];

  const fetchSAdvancePayment = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/advance/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setAdvancePayment(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstallments = async () => {
    try {
      const response = await fetch(
        `${SERVER_URL}/installments/count/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (!json.msg) {
        setInstallments(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInstallments();
    fetchSAdvancePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`flex flex-column color-${theme} gap-10 full-width`}
    >
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default AdvancePaymentComp;
