import React, { useState, useEffect, useContext } from "react";
import {
  economy,
  incentiveIcon,
  overtimeIcon,
  rewardIcon,
} from "../assets/icons";
import { AuthCountext } from "./AuthCountext";
import DashboardDiv from "./subcomponent/DashboardDiv";

const AdditionComp = () => {
  const { SERVER_URL, branchId, theme, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [increase, setIncrease] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const [incentives, setIncentives] = useState(0);
  const [rewards, setRewards] = useState(0);

  const list = [
    {
      name: "Increase",
      title: "العلاوة",
      result: increase,
      img: economy,
      link: "/increase",
    },
    {
      name: "Overtime",
      title: "الاضافي",
      result: overtime,
      img: overtimeIcon,
      link: "/overtime",
    },
    {
      name: "Incentives",
      title: "الحوافز",
      result: incentives,
      img: incentiveIcon,
      link: "/incentives",
    },
    {
      name: "Rewards",
      title: "المكافآت",
      result: rewards,
      img: rewardIcon,
      link: "/rewards",
    },
  ];

  const fetchOvertime = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/overtime/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setOvertime(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIncentives = async () => {
    try {
      const response = await fetch(
        `${SERVER_URL}/incentives/count/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (!json.msg) {
        setIncentives(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIncrease = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/increase/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setIncrease(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRewards = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/rewards/count/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setRewards(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchIncentives();
    fetchRewards();
    fetchOvertime();
    fetchIncrease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`flex flex-column color-${theme} gap-10 full-width`}
    >
      <DashboardDiv list={list} isloading={isloading} />
    </div>
  );
};

export default AdditionComp;
