import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults as df,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const colors = [
  "#f8a763",
  "#00aeef",
  "#ffd608",
  "rgba(75, 192, 192, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(201, 203, 207, 1)",
  "rgba(170, 22, 156, 1)",
  "rgba(87, 203, 200, 1)",
];

function RightBar({ datas, labels, title, titleDisplay, theme }) {
  df.font.family = "'Noto Kufi Arabic', sans-serif";
  
  const bar = [];
  datas.map((item, index) =>
    bar.push({
      label: item.name,
      data: item.children,
      grouped: true,
      borderRadius: 20,
      minBarLength: '10',
      maxBarThickness: "10",
      backgroundColor: colors[index],
      borderWidth: 0.5,
      borderColor: 'gray',
    })
  );

  const options = {
    scales: {
      x: {
        display: true,
        color: theme === "light" ? "#e3e3e3" : "#233142",
        grid: {
          display: true,
          color:
            theme === "light" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
        },
        ticks: {
          display: true,
          color: theme === "light" ? "#e3e3e3" : "#233142",
        },
      },
      y: {
        display: true,
        color: theme === "light" ? "#e3e3e3" : "#233142",
        grid: {
          display: true,
          color:
            theme === "light" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
        },
        ticks: {
          display: true,
          color: theme === "light" ? "#e3e3e3" : "#233142",
        },
      },
    },

    plugins: {
      maintainAspectRatio: true,
      responsive: true,

      colors: {
        enabled: false,
      },
      title: {
        display: titleDisplay,
        text: title,
        color: theme === "light" ? "#e3e3e3" : "#233142",
      },
      legend: {
        display: false,
        position: "bottom",
      },
      color: theme === "light" ? "#e3e3e3" : "#233142",
    },
  };

  const data = {
    datasets: bar,
  };

  return <Bar options={options} data={data} style={{ width: "100%" }} />;
}

export default RightBar;
