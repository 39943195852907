import { useState, useEffect, useContext } from "react";
import { AuthCountext } from "./AuthCountext";

export const ChartsData = () => {
  const { SERVER_URL, branchId, userToken } = useContext(AuthCountext);
  const [salaryes, setSalaryes] = useState([]);
  const [countEmployees, setCountEmployees] = useState([]);
  const [avgchart, setAvgchart] = useState([]);

  function MontName(mo) {
    const today = new Date(mo);

    // Getting full month name (e.g. "September")
    const barMonths = today.toLocaleString("default", { month: "long" });

    return barMonths;
  }

  var chMonth = salaryes;

  for (var i in chMonth) {
    if (MontName(chMonth[i].x) !== "Invalid Date") {
      chMonth[i].x = MontName(chMonth[i].x);
    }
  }

  var array = salaryes,
    barData = array.reduce((r, { title: name, ...object }) => {
      var temp = r.find((o) => o.name === name);
      if (!temp) r.push((temp = { name, children: [] }));
      temp.children.push(object);
      return r;
    }, []);

  const fetchSumSalaryes = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/sumsalarayes/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setSalaryes(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCountEmployees = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/countchart`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setCountEmployees(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAvgSalarayes = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/avgchart`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (!json.msg) {
        setAvgchart(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSumSalaryes();
    fetchCountEmployees();
    fetchAvgSalarayes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const months = [];
  const sums = [];
  salaryes.forEach((item, index) => {
    months[index] = item.x;
    sums[index] = item.y;
  });

  const branchIdChart = [];
  const branchName = [];
  const count = [];
  countEmployees.forEach((item, index) => {
    branchIdChart[index] = item.branchId;
    branchName[index] = item.title;
    count[index] = item.countChart;
  });

  const branchIdAvg = [];
  const branchNameAvg = [];
  const avgAmount = [];
  avgchart.forEach((item, index) => {
    branchIdAvg[index] = item.branchId;
    branchNameAvg[index] = item.title;
    avgAmount[index] = item.avgChart;
  });

  const employeesTotal = countEmployees.reduce((accumulator, current) => accumulator + parseFloat(current.countChart), 0);
  const avgsTotal = avgchart.reduce((accumulator, current) => accumulator + parseFloat(current.avgChart), 0) / avgchart.length;
  console.log(avgsTotal)

  return {
    barData,
    months,
    sums,
    branchIdChart,
    branchName,
    count,
    branchIdAvg,
    branchNameAvg,
    avgAmount,
    employeesTotal,
    avgsTotal
  };
};
