import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";

function ParentComp({ children, title, prevPage, linkto, button }) {
  const { theme, setTitle } = useContext(AuthCountext);
  setTitle("نظام الرواتب | " + title);
  return (
    <>
      <div
        className="flex flex-row justify-space-between full-width font-500 font-large media-paddin-top"
        style={{ paddingBlock: "40px" }}
      >
        <div>
          {linkto && (
            <Link className={`text-decoration color-${theme}`} to={linkto}>
              <span>{prevPage}</span>

              <span>/ </span>
            </Link>
          )}
          <span className="color-gray">{title}</span>

          <span>/</span>
        </div>
        {button}
      </div>
      <div
        className={`flex flex-column align-center justify-start color-${theme} card-media-width media-align-self full-width`}
        style={{ flexGrow: "1" }}
      >
        {children}
      </div>
    </>
  );
}

export default ParentComp;
